import React, { useEffect } from "react";
import { navigate } from "gatsby";

const SignupPage: React.FC = () => {
  useEffect(() => {
    navigate("/app/signup");
  }, []);

  return null;
};

export default SignupPage;
